import packageInfo from '../../package.json';

const hostMs = "https://cdev.habitatmx.axks.ddns.net";
const domPortal = "dev.habitatmx.axks.ddns.net";

export const environment = {
  production: false,
  uat: false,
  dev: true,
  version: packageInfo.version,
  sprofile: hostMs + '/ms/autentica/profile',
  loginMs: hostMs + '/ms/autentica/login',
  logoutMs: hostMs + '/ms/autentica/logout',
  perfilMs: hostMs + '/ms/autentica/profile',
  rebootPass: hostMs + '/ms/autentica/user',
  notifMs: hostMs + '/ms/notificaciones/notificaciones',
  notifRegGui: hostMs + '/gui/gtools/notif',

  messages: {
    error: {
      autentica: "Usuario o contraseña no válida."
    }
  },
  empresas: [
    { id: 1, domain: domPortal },
  ],
  theme: {
    default: 'https://vw.detonador.axkans.net/habitatmx/gassets/styles/theme/blue-gray/theme.css'
  }

};